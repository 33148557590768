import { customElement, autoinject, computedFrom } from 'aurelia-framework';
import { ProductService } from '../../../../services';
import { GraphQLBaseViewModel } from '../../../common/GraphQLBaseViewModel';
import { getLogger } from 'aurelia-logging';
import gql from 'graphql-tag';
import {
  ProductEditorDetailsQuery,
  ProductEditorDetailsQuery_product,
  ProductEditorDetailsQuery_product_translations,
  ProductEditorDetailsQuery_product_staalduinenProductMap,
  ProductEditorDetailsQueryVariables
} from '../../../../../custom_typings/graphql';
import './productdetails.css';
import { Router } from 'aurelia-router';
import { distinct, isNone } from '../../../../utility';
import { I18N } from 'aurelia-i18n';

@autoinject()
@customElement('product-details')
export class ProductEditorDetails extends GraphQLBaseViewModel<
  void,
  ProductEditorDetailsQuery,
  ProductEditorDetailsQueryVariables
> {
  constructor(
    private productService: ProductService,
    private router: Router,
    private i18n: I18N
  ) {
    super(getLogger(ProductEditorDetails.name));
  }

  pendingProductProperties: Partial<ProductEditorDetailsQuery_product> = {};
  pendingProductTranslations: ProductEditorDetailsQuery_product_translations[] = [];
  pendingStaalduinenMappings: Partial<
    ProductEditorDetailsQuery_product_staalduinenProductMap
  > = {};
  pendingHasStaalduinenMappings: boolean | undefined;

  @computedFrom('pendingProductProperties', 'data.product')
  get pendingProduct(): ProductEditorDetailsQuery_product {
    return {
      code: '',
      color: null,
      density: null,
      description: '',
      languageKey: '',
      translations: [],
      staalduinenProductMap: null,
      name: '',
      ...(this.data && this.data.product ? this.data.product : {}),
      ...this.pendingProductProperties,
      productId: this.variables ? this.variables.productId : '0'
    };
  }

  @computedFrom(
    'data.product',
    'pendingStaalduinenMappings',
    'pendingHasStaalduinenMappings'
  )
  get pendingStaalduinen() {
    if (
      !isNone(this.pendingHasStaalduinenMappings) &&
      !this.pendingHasStaalduinenMappings
    )
      return null;
    const hasSomePendingMappings = !this.pendingStaalduinenMappings;
    const currentData =
      this.data && this.data.product && this.data.product.staalduinenProductMap;

    if (
      (!isNone(this.pendingHasStaalduinenMappings) &&
        this.pendingHasStaalduinenMappings) ||
      hasSomePendingMappings ||
      currentData
    ) {
      return {
        ...currentData,
        ...this.pendingStaalduinenMappings
      };
    }
    return null;
  }

  staalduinenPropertyChanged(
    key: keyof ProductEditorDetailsQuery_product_staalduinenProductMap,
    value: ProductEditorDetailsQuery_product_staalduinenProductMap[keyof ProductEditorDetailsQuery_product_staalduinenProductMap]
  ) {
    this.pendingStaalduinenMappings = {
      ...this.pendingStaalduinenMappings,
      [key]: value
    };
  }

  @computedFrom('selectedLanguage', 'data', 'pendingProductTranslations')
  get pendingTranslationValue() {
    const fromPending = this.pendingProductTranslations.find(
      t => t.languageCode === this.selectedLanguage
    );
    if (fromPending) {
      return fromPending.translation;
    }
    const fromOriginal =
      this.data &&
      this.data.product &&
      this.data.product.translations.find(
        t => t.languageCode === this.selectedLanguage
      );
    if (fromOriginal) return fromOriginal.translation;
    return '';
  }

  setPendingTranslation(value: string) {
    if (!this.selectedLanguage) return;
    this.pendingProductTranslations = [
      { key: '', languageCode: this.selectedLanguage, translation: value },
      ...this.pendingProductTranslations.filter(
        p => p.languageCode !== this.selectedLanguage
      )
    ];
  }

  query = gql`
    query ProductEditorDetailsQuery($productId: ID!) {
      product(productId: $productId) {
        code
        color
        density
        description
        languageKey
        name
        productId
        translations {
          key
          languageCode
          translation
        }
        staalduinenProductMap {
          goodsDescription
          goodsId
          pickupPoint {
            code
            name
            staalduinenPickupPointId
          }
        }
      }
    }
  `;

  _selectedLanguage: string | undefined;
  @computedFrom('_selectedLanguage')
  get selectedLanguage() {
    if (this._selectedLanguage) return this._selectedLanguage;
    return this.i18n.getLocale();
  }

  activate({ productId }: { productId: string | undefined }) {
    this.pendingProductProperties = {};
    this.pendingProductTranslations = [];
    if (productId) {
      this.variables = {
        productId
      };
    }
  }

  saveChanges = async (
    staalduinenProductMap: ProductEditorDetailsQuery_product_staalduinenProductMap
  ) => {
    let toSave: ProductEditorDetailsQuery_product = {
      ...this.pendingProduct,
      translations: distinct(
        [
          ...this.pendingProductTranslations,
          ...this.pendingProduct.translations
        ],
        i => i.languageCode
      ),
      staalduinenProductMap
    };
    await this.productService.update(toSave);

    this.closeAndDiscard();
    this.revalidateAllActiveQueries();
    this.pendingProductProperties = {};
    this.pendingProductTranslations = [];
  };

  closeAndDiscard() {
    this.router.navigateToRoute('productmanagerlist');
  }

  dispatchProductChanged(
    property: keyof ProductEditorDetailsQuery_product,
    value: ProductEditorDetailsQuery_product[keyof ProductEditorDetailsQuery_product]
  ) {
    this.pendingProductProperties = {
      ...this.pendingProductProperties,
      [property]: value
    };
  }
}
