import {
  ProductEditorQuery,
  ProductEditorQuery_products,
  ProductEditorQuery_products_translations
} from '../../../../../custom_typings/graphql';
import { GraphQLBaseViewModel } from '../../../common/GraphQLBaseViewModel';
import { getLogger } from 'aurelia-logging';
import gql from 'graphql-tag';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import './productlist.css';

@autoinject()
export class ProductEditorList extends GraphQLBaseViewModel<
  void,
  ProductEditorQuery,
  void
> {
  constructor(private router: Router, private i18n: I18N) {
    super(getLogger(ProductEditorList.name));
  }

  query = gql`
    query ProductEditorQuery {
      products {
        code
        color
        density
        description
        languageKey
        name
        productId
        translations {
          key
          languageCode
          translation
        }
        staalduinenProductMap {
          goodsId
        }
      }
    }
  `;

  showTranslation = (
    translations: ProductEditorQuery_products_translations[]
  ) => {
    const selectedLanguage = this.i18n.getLocale();
    const translation = translations.find(
      t => t.languageCode === selectedLanguage
    );
    return translation?.translation || '';
  };

  linkToEdit = (item: ProductEditorQuery_products) => {
    return `products/edit/${item.productId}`;
  };

  createProduct() {
    this.router.navigateToRoute('productmanagerdetails');
  }

  openSafetySheet = () =>
    window.open(
      'http://chemmate3.yara.com/SDS/dologin_loc.aspx?RootLoc=\\Yara\\Gas%20and%20chemicals',
      '_blank'
    );
}
