import { autoinject, PLATFORM } from 'aurelia-framework';
import { RouterConfiguration } from 'aurelia-router';
import './products.css';

@autoinject()
export class ProductEditorViewModel {
  configureRouter(config: RouterConfiguration) {
    config.map([
      {
        route: '',
        name: 'productmanagerlist',
        moduleId: PLATFORM.moduleName('./modules/productlist/productlist')
      },
      {
        route: 'edit/:productId?',
        name: 'productmanagerdetails',
        moduleId: PLATFORM.moduleName('./modules/productdetails/productdetails')
      }
    ]);
  }
}
